<template>
  <div class="page" style="text-align: center;">
    <div class="content">
      <div class="search">
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
        >
          <div style="margin-bottom: 32px;">
            <div style="color: #2A3A59;font-size:20px;font-weight: 500;margin-bottom: 30px;">修改密码</div>
            <div style="color: #2A3A59;font-size:16px;margin-bottom: 20px;">请输入原密码</div>
            <a-form-model-item prop="oldPassword ">
              <a-input-password type="pass" style="width:340px;" v-model="form.oldPassword " placeholder="请输入" />
            </a-form-model-item>
          </div>
          <div style="margin-bottom: 32px;">
            <div style="color: #2A3A59;font-size:16px;margin-bottom: 23px;">请输入新密码</div>
            <div style="margin-bottom: 11px;">新密码为<span style="color:#ef4b5b">8-24</span>位，只能由<span style="color:#ef4b5b">数字+大小写字母+特殊字符</span>组成，最低包含三种</div>
            <a-form-model-item prop="newPassword">
              <a-input-password style="width:340px;" type="pass" @change="passWord" v-model="form.newPassword" placeholder="请输入" />
            </a-form-model-item>
          </div>
        </a-form-model>
        <a-button :loading="loading" @click="handleOk" type="primary">提交</a-button>
      </div>

    </div>
  </div>
</template>

<script>
import { appModifyPass } from '@/api/app'

export default {

  data () {
    return {
      form: {
        oldPassword: '',
        newPassword: ''
      },
      disabled: true,
      licencePlate: '',
      loading: false,
      rules: {
        oldPassword: [{ required: true, message: '必填', trigger: 'blur' }
        // ,
        //   {
        //     validator: (rules, value, callback) => {
        //       const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
        //       if (!reg.test(value)) {
        //         return callback(new Error('密码至少包含大写字母，小写字母，数字，且不少于8位'))
        //       } else {
        //         return callback()
        //       }
        //     }
        //   }
        ],
        newPassword: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.form.oldPassword = ''
    this.form.newPassword = ''
  },
  methods: {
    /**
     * 密码验证（密码复杂度：(1)大写字母(2)小写字母(3)数字(4)特殊符号，密码至少需包含上述情形中的三种）
     * @param valPWD输入的密码信息
     * @return 返回验证信息
     **/
    passwordValid (valPwd) {
      let msg = ''
      let degreeNumber = 0
      let bPwd = false

      if (valPwd == null || valPwd.length < 8 || valPwd.length > 24 || valPwd.trim().length === '') {
        bPwd = false
      } else {
        bPwd = true
      }
      const arrVerify = [
        { regName: 'Number', regValue: /^.*[0-9]+.*/ },
        { regName: 'LowerCase', regValue: /^.*[a-z]+.*/ },
        { regName: 'UpperCase', regValue: /^.*[A-Z]+.*/ },
        { regName: 'SpecialCharacters', regValue: /^.*[^a-zA-Z0-9]+.*/ }
      ]
      let regNum = 0// 记录匹配的次数
      for (let iReg = 0; iReg < arrVerify.length; iReg++) {
        if (arrVerify[iReg].regValue.test(valPwd)) {
          regNum = regNum + 1
        }
      }
      degreeNumber = regNum
      if (regNum <= 2) {
        msg = '密码必须包含大小写字母和数字'
      }
      const objReturn = {
        msg: msg,
        degreeNumber: degreeNumber,
        bPwd: bPwd
      }
      return objReturn
    },
    passWord () {

    },
    handleOk () {
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          setTimeout(() => {
            this.loading = false
          }, 2000)
          appModifyPass(this.form).then((res) => {
            this.loading = false
            this.$message.success('操作成功')
            this.$router.replace('/')
          })
        } else {
          return false
        }
      })
    },
    callback () {

    }
  }
}
</script>

<style scoped lang="less">
/deep/ .page{
  width: 100%;

  .content{
    height: 100%;
    position: relative;
    background: #ffffff;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 24px 0 0 28px;
    .search{
      height: 100%;
      margin-bottom: 20px;
    }
  }
}

</style>>
